/*
Sets the program button link based on the users select box inputs
 */

// Add event listener to select boxes
['#selectDisease', '#selectInsurance'].forEach(selectField => {
  document.querySelector(selectField)?.addEventListener("change", () => {
    findProgram()
  })
})

// Programs
// - Insurance independent programs
const unassignedDiseases = {
  'Alkoholismus'     : 'https://de.vorvida.com/',
  'Borderline'       : 'https://priovi.de/',
  'Brustkrebs'       : 'https://optimune.de/',
  'Multiple Sklerose': 'https://levidex.de/'
}

// - 'DAK-Gesundheit' programs
const dakDiseases = {
  'Depression - Jugendliche ab 12 J.': 'https://veo-team.de/',
  'Rückenschmerzen'                  : 'https://recovita.de/',
  'Ängste'                           : 'https://veovita-plus.de/',
  'Burnout'                          : 'https://veovita-plus.de/',
  'Depression - Erwachsene'          : 'https://veovita-plus.de/'
}

// - 'IKK classic' programs
const ikkDiseases = {
  'Ängste'                 : 'https://valecura-plus.de/',
  'Burnout'                : 'https://valecura-plus.de/',
  'Depression - Erwachsene': 'https://valecura-plus.de/'
}

// - 'DAK-Gesundheit' and 'IKK classic' unrelated programs
const dakIkkUnrelated = {
  'Ängste'                 : 'https://de.velibra.com/',
  'Depression - Erwachsene': 'https://de.deprexis.com/'
}

// Function to find the program based on the users select box inputs
function findProgram () {
  const disease      = document.querySelector('#selectDisease').value
  const insurance    = document.querySelector('#selectInsurance').value
  const btnProgram   = document.querySelector('#btnProgram')
  const linkProgram  = document.querySelector('#linkProgram')
  var   foundProgram = false

  if (disease in unassignedDiseases) {

    foundProgram = unassignedDiseases[disease]

  } else if (insurance === 'DAK-Gesundheit') {

    foundProgram = disease in dakDiseases ? dakDiseases[disease] : false

  } else if (insurance === 'IKK classic') {

    foundProgram = disease in ikkDiseases ? ikkDiseases[disease] : false

  } else if (insurance !== 'DAK Gesundheit' && insurance !== 'IKK classic') {

    foundProgram = disease in dakIkkUnrelated ? dakIkkUnrelated[disease] : false

  }

  if (disease && insurance && foundProgram) {

    linkProgram.setAttribute('href', foundProgram)
    btnProgram.innerHTML = 'Jetzt Programm entdecken'
    btnProgram.removeAttribute('disabled')

  } else if (disease && insurance) {

    linkProgram.setAttribute('href', '#')
    btnProgram.innerHTML = 'Kein Programm verfügbar'
    btnProgram.setAttribute('disabled', true)

  } else {

    linkProgram.setAttribute('href', '#')
    btnProgram.innerHTML = 'Erkrankung & Krankenkasse auswählen'
    btnProgram.setAttribute('disabled', true)

  }
}