(function ($) {

    $.fn.looperFade = function () {

        const $slides = $(this);
        let index = -1;

        function showNextSlide() {
            ++index;
            $slides.eq(index % $slides.length)
                .fadeIn(2000).css("display","inline-block")
                .delay(5000)
                .fadeOut(2000, showNextSlide);
        }


        showNextSlide();
    }

}(jQuery));






















