/*
Handles the partner form
 */

// Add event listeners to the submit buttons
document.querySelector('#btnPartnerInsurerSubmit')?.addEventListener('click', () => {
  sendMail('insurer')
})

document.querySelector('#btnPartnerEmployerSubmit')?.addEventListener('click', () => {
  sendMail('employer')
})

// Validate a field
function validateField(inputValue, fieldName) {
  const fields = {
    name    : ['empty', 'length'],
    phone   : ['empty', 'number', 'length'],
    email   : ['empty', 'email'],
    insurer : ['empty'],
    employer: ['empty'],
    message : [],
    privacy : ['checked']
  }

  var validationStatus = true

  fields[fieldName].forEach((validation) => {
    if (validationStatus) {
      switch (validation) {
        case 'empty':
          if (!inputValue || inputValue.trim() === '') {
            validationStatus = false
          }
          break

        case 'length':
          if (!inputValue || inputValue.length > 255) {
            validationStatus = false
          }
          break

        case 'email':
          const reEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (!reEmail.test(inputValue.trim())) {
            validationStatus = false
          }
          break

        case 'number':
          if (isNaN(inputValue)) {
            validationStatus = false
          }
          break

        case 'checked':
          if (inputValue.checked == false) {
            validationStatus = false
          }
          break
      }
    }
  })

  return validationStatus
}

async function sendMail(customer) {

  $('#loading-spinner').removeClass('d-none')

  // Build form data object
  let formData = {
    name   : document.querySelector('#name').value,
    phone  : document.querySelector('#phone').value,
    email  : document.querySelector('#email').value,
    message: document.querySelector('#message').value,
    privacy: document.querySelector('#privacy')
  }

  if (customer === 'insurer') {
    formData.insurer = document.querySelector('#insurer').value
  } else if (customer === 'employer') {
    formData.employer = document.querySelector('#employer').value
  }

  // Validate each field
  let validationStatus = true

  Object.keys(formData).forEach(field => {
    if (!validateField(formData[field], field)) {
      validationStatus = false
    }
  })

  // Send mail to /mail-endpoint
  if (validationStatus) {
    
    delete formData.privacy
    formData.sender     = 'veovita@broca.io'
    formData.mailto     = ['kam@veovita.de']
    formData.subject    = 'Kontaktaufnahme über veovita.de'
    formData.submission = 'direct'

    $.ajax({
      url: '/mail',
      type: 'POST',
      data: formData,
      dataType: 'json',
      cache: false,
      success: () => {
        document.querySelector('#loading-spinner')     .classList.add('d-none')
        document.querySelector('#txt-validation-error').classList.add('d-none')
        document.querySelector('#txt-submit-error')    .classList.add('d-none')
      },
      error: (err) => {
        console.log(formData, err)
        document.querySelector('#loading-spinner')     .classList.add('d-none')
        document.querySelector('#txt-validation-error').classList.add('d-none')
        document.querySelector('#txt-submit-error')    .classList.remove('d-none')
      }
    })

  } else {
    console.log(formData)
    document.querySelector('#loading-spinner')     .classList.add   ('d-none')
    document.querySelector('#txt-submit-error')    .classList.add   ('d-none')
    document.querySelector('#txt-validation-error').classList.remove('d-none')
  }

}