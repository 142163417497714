// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

// Bob scripts form Libs
import '../scripts/fromLibs/glyphicons.js';
import '../scripts/fromLibs/linkTap.js';
import '../scripts/fromLibs/scrollTop_arrow.js';
import '../scripts/fromLibs/scrollTo_anchors.js';
import '../scripts/fromLibs/tracking_class_creator.js';
import '../scripts/fromLibs/fadePage.js';
import '../scripts/fromLibs/menu.js';

// Project specific scripts
import '../scripts/main.js';

import '../scripts/menu_active.js';
import '../scripts/programFinder.js';
import '../scripts/intersectionObserver.js';
import '../scripts/bobframe6.js';
import '../scripts/bobmsg4.js';
import '../scripts/formPartner.js';
import '../scripts/formPro.js';
import '../scripts/util2.js';
import '../scripts/spinner.js';
import '../scripts/ValidationTarget.js';
import '../scripts/looperFade.js';
import '../scripts/etrackerButtonEvents.js';

