import * as Globals from './globals_veovita.js';

/**
 * sends an email via the /mail endpoint
 */
export function  sendMail (data) {

    const g = Globals;
    let $submitButton = $(g.FRAME_FORM_SELECTOR + ' .submit')
    let classForm = $(g.FRAME_FORM_SELECTOR).attr('class');

    $('document').spinner('show');

    $.ajax({
        url: "/mail",
        type: "POST",
        data: data,
        cache: false,
        success: function () {

            _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                'src': '#s2-bob-wizard-success2'
            });

            // myObject, myCategory, myAction
            // _.eTracker2(classForm, 'form', 'kontakt-form-pro');

        },
        error: function (err) {

            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);
            console.error(err);

        },
        complete: function () {
            $submitButton.prop('disabled', false)
            $('document').spinner('hide');
        }
    });
}