import * as Globals from './globals_veovita.js';
/*
Handles what happens when the form is submitted
Initates validation
Initaites a request with the forms data as a parameter
 */
export function formsHandleSubmit(e, form) {

    const g = Globals;
    const $submitButton = $(g.FRAME_FORM_SELECTOR + ' .submit')

    e.preventDefault();

    $submitButton.prop('disabled', true)

    _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_SUBMIT);

    if (!form.validate()) {

        $submitButton.prop('disabled', false)

        _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_WARNING);

        return;
    }

    form.makeRequest(form.getData())
}