(function ($) {

  $(document).ready(function () {

      $(".slide-text").looperFade();

      $(".slide-subtext").looperFade();

      $('body').etrackerButtonEvents();

      $('.glyphicons').glyphClone();

      $('.body-content').linkTap();

      $(window).scrollTopArrow();

      $('.navbar').menuScrollAnimations();

      $('.navbar').menuActive();

      $('body').fadePage();
      
      $('.bob-frame-show').bobframe();

  });

}(jQuery));
