(function ($) {

    /**
     * 
     */
    $.fn.etrackerButtonEvents = function () {

        let sendEventEtracker = function (e) {

            let $btn = $(e.target);
            let page = $('body').attr("class").match(/[\w-]*page[\w-]*/g)[0].replace('age_', '');
            let eObjectNames = [
                $btn.text() ? $btn.text().trim().replace(/[^a-z0-9\s]/gi, '').replaceAll(' ', '_').toLowerCase() : '',
                $btn.attr("aria-label") ? $btn.attr("aria-label").trim().replace(/[^a-z0-9\s]/gi, '').replaceAll(' ', '_').toLowerCase() : '',
                $btn.attr('id') ? $btn.attr('id') : '',
                $btn.attr('class') ? $btn.attr('class').split(" ")[0] : '',
                "no-value"
            ]

            // for(const eObjectName of eObjectNames) {
            //    if(eObjectName.length > 0) {
            //       _.eTracker2(`${eObjectName} ${page}` , 'button', 'click');
            //       break;
            //    }
            // }
        }

        $(document).on('click', 'button', function (e) {
            sendEventEtracker(e);
        });

    };

}(jQuery));