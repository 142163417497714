import * as Globals from './globals_veovita.js';

/*
returns the data object that satisfies requirements for the mail endpoint
while using Globals and _.getFormData function
*/
export function getData() {

    let g = Globals;
    let data = _.getFormData(g.FRAME_FORM_SELECTOR).data;
    let label = _.getFormData(g.FRAME_FORM_SELECTOR).label;
    let checkedItems = $.map($('input:checked'), function (c) {
        return $(c).attr('aria-label');
    });

    return {
        "name": `${data.firstname} ${data.lastname}`,
        "email": `${data.email}`,
        "description": `${checkedItems.join('\r\n')} ${data.description}
        ${label.phone}: ${data.phone}
        ${label.appointment}: ${data.appointment}`,
        "subject": data.subject,
        "mailto[]": data['mailto[]'],
        "submission": data.submission,
        "sender": data.sender,
    }
}