import * as Globals from './globals_veovita.js';
import {formsHandleSubmit} from "./formsHandleSubmit";
// form specific imports below
import {handleCheck} from "./formProHandleCheck";
import {sendMail} from "./formProSendMail";
import {validateForm} from "./formProValidateForm";
import {getData} from "./formProGetData";

(function ($) {

    $.fn.formPro = function (options) {

        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }

        const settings = $.extend({}, options);
        const event_namespace = '.bobWizard5';
        const g = Globals;
        const $form = $(g.FRAME_FORM_SELECTOR);
        const $formInputsCheckbox = $form.find('.checkbox input');

        // Initate the bobMsg plugin for this form
        $form.bobMsg(settings);

        $form.off(event_namespace).on('submit' + event_namespace, function (e) {
            formsHandleSubmit(e, {
                validate: validateForm,
                getData: getData,
                makeRequest: sendMail
            })
        });

        $formInputsCheckbox.off(event_namespace).on("change" + event_namespace, function (e) {
            handleCheck(e);
        });

        return this;

    };

}(jQuery));