import ValidationTarget from "./ValidationTarget";
import * as Globals from './globals_veovita.js';

/**
 * Validates the form inputs
 * handles invalid and valid input fields
 * returns true if valid, or false if not
 */
export function validateForm() {

    let g = Globals;
    let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);
    let results = [];

    let validationTargets = [
        formData.values.firstname,
        formData.values.lastname,
        formData.values.position,
        formData.values.email,
        formData.values.phone,
        formData.values.appointment,
    ];

    for (let key in formData.checkboxes) {
        validationTargets.push(formData.checkboxes[key])
    }

    if ($(g.FRAME_FORM_SELECTOR + ' .form-group-description').hasClass('show')) {
        validationTargets.push(formData.values.description)
    }

    validationTargets.forEach(function (item) {
        let that = new ValidationTarget(item)
        that.hasFailedToValidate() ? that.handleInvalid() : that.handleValid();
        results.push(that.isValid);
    })

    return results.every(el => el === true);
}