(function ($) {

    $.fn.menuActive = function () {

        /**
         * matchesPage
         * This checks if an element has the same href attribute as the current page
         * @param element // needs to be an jQuery element
         */
        let matchesPage = function (element) {
            let current = location.pathname;

            // here the matching happens except index page
            if (element.attr('href').indexOf(current) > -1 && current !== '/') {
                return true
            }

            // here checking if current page is index page
            if (current === '/' && element.attr('href') === '/') {
                return true
            }

            // check if current page is a child page
            if (element.attr('href') === '#nav-customers' && ['/krankenkassen', '/arbeitgeber'].includes(current)) {
                return true
            } else if (element.attr('href') === '#nav-veovita' && ['/ueber-veovita'].includes(current)) {
                return true
            }

            return false
        }

        // it gives each element class active if it matches the current page
        $('.nav-link').each(function () {
            let $this = $(this);

            if (matchesPage($this)) {
                $this.addClass('activeItem');
            }
        })
    }
}(jQuery)); 

