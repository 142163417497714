export default class ValidationTarget {

    isValid = false;
    value;
    element;

    constructor(obj) {
        this.value = obj.value
        this.element = obj.element
    }

    handleValid() {
        this.isValid = true;

        if (this.element.attr('name') === 'radio') {
            $('.form-group-radio', '.bob-frame').removeClass('is-invalid');
            return
        }

        this.element.removeClass('is-invalid');
    }

    handleInvalid() {
        this.isValid = false

        if (this.element.attr('name') === 'radio') {
            $('.form-group-radio', '.bob-frame').addClass('is-invalid');
            return
        }

        this.element.addClass('is-invalid');
    }

    hasFailedToValidate() {

        if (this.element.attr('name') === 'referrer') {
            return this.value === "0"
        }

        else if (this.element.attr('name') === 'phone') {
            return this.value.trim() === '' || _.validatePhone(this.value.trim()) === false
        }

        else if (this.element.attr('name') === 'email') {
            return this.value.trim() === '' || _.validateEmail(this.value.trim()) === false
        }

        else if (this.element.attr('name') === 'radio') {
            return $('input[type=radio]:checked', '.bob-frame').length === 0
        }

        else if (this.element.attr('type') === 'checkbox') {
            return $('input[type=checkbox]:checked', '.bob-frame').length === 0
        }

        else {
            return this.value.trim() === '' || this.value.trim().length > this.element.attr('maxlength')
        }
    }

}